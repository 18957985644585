const HeaderDataAlpha = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "About",
    link: "/about-us",
  },
  {
    title: "Our Products",
    dropdown: [
      {
        title: "Off Grid Solar Project",
        link: "/services/off-grid-solar-project",
      },
      {
        title: "On Grid Solar Project",
        link: "/services/on-grid-solar-project",
      },
      {
        title: "Hybrid Solar Project",
        link: "/services/hybrid-solar-project",
      },
    ],
  },
  {
    title: "Projects",
    link: "/projects",
  },
  {
    title: "Contact Us",
    link: "/contact",
  },
];

export default HeaderDataAlpha;
