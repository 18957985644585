import React, { lazy } from "react";

// Libraries
import { Link } from "react-router-dom";
import { Col, Navbar } from "react-bootstrap";

import brandLogo from "../../../Assets/img/AlphaSolar/logo/brand-logo.webp";
import tataPowerLogo from "../../../Assets/img/AlphaSolar/logo/tata-power-logo.webp";

// Components
// import { resetForm, sendEmail } from "../../../Theme/Functions/Utilities";
// import { Input } from "../../Components/Form/Form";
import { HeaderNav } from "./Header";

// const MessageBox = lazy(() =>
//   import("../../../Theme/Components/MessageBox/MessageBox")
// );

// const SearchBar = React.lazy(() =>
//   import("../../../Theme/Components/Header/Header").then((module) => ({
//     default: module.SearchBar,
//   }))
// );

const HamburgerMenu = React.lazy(() =>
  import("./Header").then((module) => ({
    default: module.HamburgerMenu,
  }))
);

const Menu = React.lazy(() =>
  import("./Header").then((module) => ({
    default: module.Menu,
  }))
);

const ReactCustomScrollbar = lazy(() => import("../ReactCustomScrollbar"));

const SocialIcons = lazy(() => import("../SocialIcon/SocialIcons"));

const StaticInstagram = lazy(() => import("../Instagram/StaticInstagram"));

const SocialIconsData = [
  {
    color: "#3b5998",
    link: "https://www.facebook.com/",
    icon: "fab fa-facebook-f",
  },
  {
    color: "#ea4c89",
    link: "https://dribbble.com/",
    icon: "fab fa-dribbble",
  },
  {
    color: "#00aced",
    link: "https://twitter.com/",
    icon: "fab fa-twitter",
  },
  {
    color: "#fe1f49",
    link: "https://www.instagram.com/",
    icon: "fab fa-instagram",
  },
  {
    color: "#0077b5",
    link: "https://www.linkedin.com/",
    icon: "fab fa-linkedin-in",
  },
];

const HeaderAlpha = (props) => {
  return (
    <>
      {/* Header Start */}
      <HeaderNav
        fluid="fluid"
        theme="dark"
        expand="lg"
        className="py-[0px] px-[35px] md:px-[15px] md:py-[20px] sm:px-0"
      >
        <Col lg={2} sm={6} xs={"auto"} className="mr-auto ps-0">
          <Link aria-label="header logo" className="flex items-center" to="/">
            <Navbar.Brand className="inline-block p-0 m-0">
              <img
                className="default-logo"
                width="111"
                height="36"
                src={brandLogo}
                data-rjs={brandLogo}
                alt="logo"
              />
              <img
                className="alt-logo"
                width="111"
                height="36"
                src={brandLogo}
                data-rjs={brandLogo}
                alt="logo"
              />
              <img
                className="mobile-logo"
                width="111"
                height="36"
                src={brandLogo}
                data-rjs={brandLogo}
                alt="logo"
              />
            </Navbar.Brand>
          </Link>
        </Col>
        <Navbar.Toggle className="order-last md:ml-[17px] w-[25px] min-h-[15px] inline-block align-middle">
          <span className="navbar-toggler-line"></span>
          <span className="navbar-toggler-line"></span>
          <span className="navbar-toggler-line"></span>
          <span className="navbar-toggler-line"></span>
        </Navbar.Toggle>
        <Navbar.Collapse className="justify-center col-auto col-lg-8">
          <Menu {...props} />
        </Navbar.Collapse>
        <Col lg={2} xs={"auto"} className="justify-end pe-0 flex items-center">
          {/*<SearchBar />*/}

          {/** right logo start */}

          <Link aria-label="header logo" className="flex items-center" to="/">
            <Navbar.Brand className="inline-block p-0 m-0">
              <img
                className="default-logo"
                width="111"
                height="36"
                src={tataPowerLogo}
                data-rjs={tataPowerLogo}
                alt="logo"
              />
              <img
                className="alt-logo"
                width="111"
                height="36"
                src={tataPowerLogo}
                data-rjs={tataPowerLogo}
                alt="logo"
              />
              <img
                className="mobile-logo"
                width="111"
                height="36"
                src={tataPowerLogo}
                data-rjs={tataPowerLogo}
                alt="logo"
              />
            </Navbar.Brand>
          </Link>
          {/** right logo ends */}
          <div className="md:hidden pl-[17px]">
            <HamburgerMenu className="" theme="dark">
              <Col className="flex flex-col justify-center px-[50px] py-[70px] w-[500px] h-[100vh] shadow-[0_0_20px_rgba(0,0,0,0.3)] right-0 text-center bg-white">
                <ReactCustomScrollbar className="pl-[15px]" theme="dark">
                  <div>
                    <h1 className="mb-0 font-bold tracking-[-3px] text-darkgray font-serif uppercase">
                      Hello
                    </h1>
                    <p className="text-lg text-[#27ae60] font-serif uppercase block">
                      Let's be friends.
                    </p>
                    <div className="my-20 w-full">
                      <StaticInstagram className="" />
                    </div>
                    <p className="w-[70%] mb-12 text-darkgray leading-[26px] text-lg font-serif mx-auto inline-block">
                      Get latest update for our trusted applications
                    </p>

                    <SocialIcons
                      theme="social-icon-style-05"
                      size="sm"
                      iconColor="dark"
                      data={SocialIconsData}
                    />
                  </div>
                </ReactCustomScrollbar>
              </Col>
            </HamburgerMenu>
          </div>
        </Col>
      </HeaderNav>
      ;{/* Header End */}
    </>
  );
};

export default HeaderAlpha;
