import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Parallax } from "react-scroll-parallax";
import Overlap from "../../Components/Overlap/Overlap";
import { fadeIn } from "../../Functions/GlobalAnimations";
import { m } from "framer-motion";
import Buttons from "../../Components/Button/Buttons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Keyboard } from "swiper";

/** css */
import "./Services.scss";

/** images */
import banner from "../../../Assets/img/AlphaSolar/service/off_grid/banner.webp";
import header_circle from "../../../Assets/img/AlphaSolar/service/off_grid/header_circle.webp";
import img1 from "../../../Assets/img/AlphaSolar/service/off_grid/des-img-1.webp";
import img2 from "../../../Assets/img/AlphaSolar/service/off_grid/des-img-2.webp";
import img3 from "../../../Assets/img/AlphaSolar/service/off_grid/des-img-3.webp";

// Data
const SwiperImgData = [
  {
    img: img1,
  },
  {
    img: img2,
  },
  {
    img: img3,
  },
];

const TableData = [
  {
    pcuCapacity: "1.5",
    pcuType: "PWM",
    batteries: "2",
    systemVoltage: "24V",
    numberOfModules: "3",
    dcCapacity: "1.59",
  },
  {
    pcuCapacity: "2.2",
    pcuType: "PWM",
    batteries: "2",
    systemVoltage: "24V",
    numberOfModules: "3",
    dcCapacity: "1.59",
  },
  {
    pcuCapacity: "3.5",
    pcuType: "PWM",
    batteries: "4",
    systemVoltage: "48V",
    numberOfModules: "6",
    dcCapacity: "3.18",
  },
  {
    pcuCapacity: "5",
    pcuType: "PWM",
    batteries: "4",
    systemVoltage: "48V",
    numberOfModules: "8",
    dcCapacity: "4.24",
  },
  {
    pcuCapacity: "2.2",
    pcuType: "MPPT",
    batteries: "2",
    systemVoltage: "24V",
    numberOfModules: "4",
    dcCapacity: "2.12",
  },
  {
    pcuCapacity: "3.5",
    pcuType: "MPPT",
    batteries: "4",
    systemVoltage: "48V",
    numberOfModules: "6",
    dcCapacity: "3.18",
  },
  {
    pcuCapacity: "5",
    pcuType: "MPPT",
    batteries: "4",
    systemVoltage: "48V",
    numberOfModules: "9",
    dcCapacity: "4.77",
  },
  {
    pcuCapacity: "5",
    pcuType: "MPPT",
    batteries: "8",
    systemVoltage: "96V",
    numberOfModules: "8",
    dcCapacity: "4.24",
  },
  {
    pcuCapacity: "7.5",
    pcuType: "MPPT",
    batteries: "10",
    systemVoltage: "120V",
    numberOfModules: "12",
    dcCapacity: "6.36",
  },
  {
    pcuCapacity: "10",
    pcuType: "MPPT",
    batteries: "10",
    systemVoltage: "120V",
    numberOfModules: "16",
    dcCapacity: "8.48",
  },
];

const OffGridSolarProject = () => {
  return (
    <div>
      {" "}
      {/* Parallax Scrolling Start */}
      <div className="h-[800px] md:h-[650px] sm:h-[350px] flex items-center overflow-hidden relative">
        <Parallax
          className="lg-no-parallax bg-cover cover-background absolute top-[0px] left-0 md:-top-[30px] w-full h-[100vh] md:h-[700px] sm:h-[400px] sm:-top-[60px]"
          translateY={[-40, 40]}
          style={{
            backgroundImage: `url(${banner})`,
          }}
        ></Parallax>
        <div className="absolute top-0 left-0 w-full h-full bg-[#374162] opacity-30"></div>
        <Container fluid className="relative xs:px-0">
          <Row className="h-full justify-center">
            <Col
              md={6}
              className="relative text-center flex flex-column justify-center"
            >
              <span className="uppercase text-sm font-serif tracking-[5px] text-white inline-block mb-[20px] font-medium sm:mb-[10px]">
                Your Gateway to
              </span>
              <h2 className="font-serif text-white font-semibold uppercase block mb-0">
                Clean Energy
              </h2>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Parallax Scrolling End */}
      {/* Section Start */}
      <section className="pb-[130px] lg:pb-[90px] md:pb-[75px] sm:py-[50px]">
        <Container className="mb-[130px] lg:mb-[90px] md:mb-[75px] sm:mb-[50px]">
          <Row>
            <Overlap className="z-10">
              <img
                height=""
                width=""
                className="rounded-circle sm:w-[30%] xs:w-[40%] border-[12px] border-white rounded-full box-shadow-extra-large mx-auto"
                src={header_circle}
                alt=""
              />
            </Overlap>
          </Row>
        </Container>
        <Container>
          <Row className="justify-center">
            <Col className="col-12 col-lg-11">
              <m.div {...fadeIn} className="row">
                <Col lg={4} className="pr-[5%] lg:pr-[15px] md:mb-[50px]">
                  <h5
                    className="font-serif text-darkgray font-medium mb-16 -tracking-[1px]"
                    style={{ color: "#004d9a" }}
                  >
                    Empowering Renewable Energy
                  </h5>
                  <ul className="pl-0">
                    <li className="border-b border-mediumgray pb-[20px] mb-[20px]">
                      <span className="uppercase text-darkgray w-[35%] inline-block font-medium font-serif text-md">
                        Solar
                      </span>
                      Sustainable Sun Solutions
                    </li>
                    <li className="border-b border-mediumgray pb-[20px] mb-[20px]">
                      <span className="uppercase text-darkgray w-[35%] inline-block font-medium font-serif text-md">
                        Energy
                      </span>
                      Empowering Tomorrow
                    </li>
                    <li className="border-b border-mediumgray pb-[20px] mb-[20px]">
                      <span className="uppercase text-darkgray w-[35%] inline-block font-medium font-serif text-md">
                        Solutions
                      </span>
                      Sun-Powered Solutions
                    </li>
                    <li>
                      <span className="uppercase text-darkgray w-[35%] inline-block font-medium font-serif text-md">
                        Range
                      </span>
                      1 KW To 10 KW
                    </li>
                  </ul>
                </Col>
                <Col lg={{ span: 7, offset: 1 }}>
                  <span
                    className="font-serif text-md uppercase font-medium mb-[20px] inline-block text-darkgray"
                    style={{ color: "#388c07" }}
                  >
                    Off grid solar project
                  </span>
                  <p className="mb-[25px] text-justify xs:text-left xs:mb-[15px]">
                    Off-grid solar energy installations provide independent,
                    renewable energy options. These systems, which use solar
                    energy to generate electricity, enable sustainable living
                    while lowering dependency on conventional power grids.
                    Off-grid solar projects offer dependable energy access
                    thanks to cutting-edge technology and seamless integration,
                    helping to create a cleaner future.
                  </p>
                  <p className="mb-[25px] text-justify xs:text-left xs:mb-[15px]">
                    Off-grid solar installations have completely changed how
                    isolated and underprivileged places can get energy. These
                    initiatives enable communities to thrive without grid
                    connections by utilising solar panels and energy storage.
                  </p>
                  <Buttons
                    ariaLabel="button"
                    to="/contact"
                    className="font-medium font-serif uppercase btn-link after:h-[2px] focus:none hover:bg-transparent after:bg-darkgray hover:text-darkgray"
                    color="#232323"
                    title="Get Quote"
                    size="lg"
                  />
                </Col>
              </m.div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}
      {/* Section Start */}
      <section className="py-0 relative">
        <Swiper
          slidesPerView="auto"
          speed={1000}
          loop={true}
          modules={[Autoplay, Keyboard]}
          autoplay={{ delay: 1500, disableOnInteraction: false }}
          keyboard={{ enabled: true, onlyInViewport: true }}
          spaceBetween={10}
          centeredSlides={true}
          breakpoints={{
            576: {
              spaceBetween: 20,
            },
            768: {
              spaceBetween: 30,
            },
          }}
          className="SingleProjectPage05 relative black-move"
        >
          {SwiperImgData.map((item, i) => {
            return (
              <SwiperSlide className="w-[55%] sm:w-[65%]" key={i}>
                <img height="" width="" src={item.img} alt="" />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </section>
      {/* Section End */}
      {/* Section Start */}
      <m.section
        className="py-[80px] lg:py-[90px] sm:py-[75px] xs:py-[50px]"
        {...fadeIn}
      >
        <Container>
          <div className="text-[#004D9A] text-lg font-semibold pb-4">
            Tata Power Solar Rooftops-Off-Grid Product Specification:
          </div>
          <div className="text-sm pl-2">With Mono nDCR 530Wp Modules</div>
          <Row>
            <Col lg={12} className="mb-[47px] md:mb-0 sm:p-1">
              <table className="default_table  table-auto border border-slate-500 mb-[60px] md:mb-[40px] xs:!mb-0">
                <thead>
                  <tr className="font-serif text-[#212529] ">
                    <th scope="col">PCU Capacity (KVA)</th>
                    <th scope="col">PCU Type</th>
                    <th scope="col">Batteries: 12 volt 150AH</th>
                    <th scope="col">System Voltage</th>
                    <th scope="col">Number of Modules</th>
                    <th scope="col">DC Capacity (KWP)</th>
                  </tr>
                </thead>
                <tbody>
                  {TableData.map((item, i) => {
                    return (
                      <>
                        <tr key={i}>
                          <td>{item.pcuCapacity}</td>
                          <td>{item.pcuType}</td>
                          <td>{item.batteries}</td>
                          <td>{item.systemVoltage}</td>
                          <td>{item.numberOfModules}</td>
                          <td>{item.dcCapacity}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}
    </div>
  );
};

export default OffGridSolarProject;
